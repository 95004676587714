@import "../../styles/mixins";

.home-separator {
  height: 72vh;
  width: 100%;
  position: relative;
}

.separator-container {
  height: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.separator-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}