@import "../../styles/mixins";

// WORKS SECTION HOME PAGE

.home-works-section {
  padding: 280px 0 160px;
  background: var(--col-dark);
  display: flex;
  position: relative;

  @include small-screen {
    padding: 160px 0;
  }
}

.works-title {
  width: fit-content;
  position: absolute;
  top: 16vh;
  left: var(--active-margin-grid);
  z-index: 2;

  @include large-screen {
    top: 20vh;
  }

  @include small-screen {
    top: 8vh;
  }

  @include mobile-screen {
    top: 16vh;
  }
}

.work-slider {
  padding: 0 var(--active-margin-grid) !important;

  .work-item {
    // Override swiper slide styles
    width: 29vw !important;
    // width: clamp(320px, 29vw, 29vw) !important;
    // width: calc((100vw - (2 * var(--active-margin-grid))) / 3) !important;
    height: auto !important;
    // aspect-ratio: 3/4 !important;
    flex-shrink: initial !important;
    will-change: transform;
    overflow: hidden;

    @include tablet-screen {
      width: 50vw !important;
    }

    @include mobile-screen {
      width: 80vw !important;
    }

    &.is-reveal {
      @for $i from 1 through 4 {
        &:nth-child(#{$i}n) {
          .work-item-image {
            transition-delay: #{0.15 * $i}s,
            0s !important;
          }
        }
      }
    }

    &:hover>a .work-canvas .work-item-image {
      transform: scale(1.15) !important;
    }

    a {
      height: 100%;
      display: flex;
      flex-flow: column;
      gap: 24px;
      justify-content: space-between;
    }

    a .work-canvas {
      width: 100%;
      height: 100%;
      overflow: hidden;
      aspect-ratio: 3/4;
    }

    a .work-item-image {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      filter: brightness(80%);
      will-change: transform;
      clip-path: inset(100% 0% 0% 0%);
      transition: clip-path 1s var(--custom-ease-in), transform .6s var(--custom-ease-out);

      &.is-reveal {
        clip-path: inset(0% 0% 0% 0%);
        scale: 1;
      }
    }

    a .work-description {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      gap: 8px;

      .work-title {
        font-weight: 400;
      }

      .work-detail {
        font-size: 1.6rem;
      }
    }
  }
}

.swiper {
  z-index: 0 !important;
}

// swiper override styles
.swiper-wrapper {
  width: fit-content !important;
  gap: 32px !important;

  @include mobile-screen {
    gap: 16px !important;
  }
}

// WORKS SECTION INNER PAGE

.works-section {
  width: 100%;
  height: 100vh;
}

.next-work-section {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;

  .next-work-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(.4);
    position: absolute;
  }

  .mid-container {
    width: 100%;
    z-index: 2;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .next-work-container {
    width: fit-content;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    cursor: pointer;

    &:hover>.next-work-img-container .next-work-img {
      transform: scale(1.2);
    }
  }

  .next-work-img-container {
    width: var(--col-3);
    height: 560px; // clamp later
    user-select: none;
    // clip-path: inset(100% 0% 0% 0%);
    transition: clip-path .8s var(--custom-ease-out);
    overflow: hidden;

    .next-work-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .8s var(--custom-ease-out);
    }
  }

  .links {
    position: absolute;
    left: var(--active-margin-grid);
    bottom: 80px;
    z-index: 10;

    .link-title {
      font-size: 1.6rem;
      margin-bottom: 16px;
    }

    .link {
      cursor: pointer;
      width: fit-content;
      margin-bottom: 8px;
    }
  }
}