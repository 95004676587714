@import "../../styles/mixins";

@keyframes loading {
    0% {
        transform: translate3d(0, 0, 0);
        scale: 1;
    }

    50% {
        transform: translate3d(0, 0, 0);
        scale: 1.2;

    }

    100% {
        transform: translate3d(0, -100%, 0);
        scale: 1.2;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--col-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: loading 2s var(--custom-ease-in) forwards;
    will-change: transform;
}

.bottom-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    bottom: auto;
    right: auto;
    background: var(--col-dark-grey);
}